import React, { useEffect, useState } from 'react';
import Layout from '../../components/structures/Layout/layout.js';
import Section from '../../components/structures/Section/section';
import Wrapper from '../../components/structures/Wrapper/wrapper';
import RichText from '../../components/modules/RichText/richText';
import { Title } from '../../components/elements/Title/title';
import { NavLateral } from '../../components/modules/NavLateral/navLateral';
import Column from '../../components/structures/Column/column';
import { Card } from '../../components/modules/Card/card';
import { CardGroup } from '../../components/modules/CardGroup/cardGroup';
import sticky from '../../services/sticky/sticky';
import setCookie from '../../services/cookie/setCookie.js';
import getCookie from '../../services/cookie/getCookie.js';
import axios from 'axios';
import contentFilter from '../../services/contentFilter/contentFilter';
import replaceVariableSection from '../../services/replaceVariableSection/replaceVariableSection';
import { useSite } from '../../context/siteContext';

const Instructions = (props) => {
  const location = props.path;
  const variables = require('../../data/variables.json');
  const variablesForms = variables.forms;
  let sections = props.pageContext.sections;
  const dataSite = props.pageContext.dataSite;

  let { forms, feeBiometrical } = useSite() ? useSite() : {};

  //replace variables Contntentful with API data
  for (const form in variablesForms) {
    if (typeof dataSite.data !== 'undefined') {
      sections = replaceVariableSection(variablesForms[form].fee, dataSite.data.forms[form].fee, sections);
    } else {
      sections = replaceVariableSection(variablesForms[form].fee, typeof forms !== 'undefined' && forms[form] ? forms[form].fee : '', sections);
    }
  }
  let feeTotal;
  if (typeof dataSite.data !== 'undefined') {
    feeTotal = parseFloat(props.pageContext.dataSite.data.forms['I-90'].fee) +
      parseFloat(feeBiometrical ? feeBiometrical : 0);
  } else {
    feeTotal = forms ? parseFloat(forms['I-90'].fee) : 0 + parseFloat(feeBiometrical ? feeBiometrical : 0);
  }

  sections = replaceVariableSection('{{FEE_TOTAL_APPLICATION_I-90}}', feeTotal, sections);
  sections = replaceVariableSection('{{FEE_BIOMETRICAL_US}}', feeBiometrical ? feeBiometrical : '', sections);


  const shortName = props.pageContext.shortName;
  const sectionPdfNote = contentFilter(sections, 'section-note-pdf');
  const sectionInstructions = contentFilter(sections, `instructions-${shortName}`);
  const navLateral = props.pageContext.navLateral;
  const name = props.pageContext.name;
  const textLoading = props.pageContext.textLoading;

  let arrayCat = [];

  let application;

  const [data, setData] = useState({ hits: [] });
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const templateStyle = props.pageContext.templateStyle;

  typeof data.application !== 'undefined' && (application = data.application);

  typeof data.categories !== 'undefined' &&
    Object.keys(data.categories).forEach((key) => {
      if (data.categories[key].value !== '') {
        arrayCat.push(data.categories[key].name + '_' + data.categories[key].value);
      }
    });
  useEffect(() => {
    sticky();
    const jsonN400Outusa = {
      form_name: 'N-400',
      application: '',
      categories: [{ name: 'category', value: 'outusa' }],
    };
    const jsonN400Inusa = {
      form_name: 'N-400',
      application: '',
      categories: [{ name: 'category', value: 'inusa' }],
    };
    const searchParams = new URLSearchParams(window.location.search);
    let customForm = searchParams.get('customForm');

    if (customForm === null) {
      let customFormFromCookie = getCookie('customForm');
      setCookie('customForm', customFormFromCookie, 1);
      customForm = customFormFromCookie;
    } else {
      setCookie('customForm', customForm, 1);
    }

    let formId = customForm;
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);
      try {
        const result = await axios(`${process.env.API_URL}/customer-forms/instructions/${formId}`);
        setData(result.data);
      } catch (error) {
        setIsError(true);
      }
      setIsLoading(false);
    };
    if (formId !== 'staticoutusa' && formId !== 'staticinusa') {
      fetchData();
    } else {
      customForm === 'staticoutusa' && setData(jsonN400Outusa);
      customForm === 'staticinusa' && setData(jsonN400Inusa);
      setIsError(false);
    }

    const tableCategories = document.getElementById('m-table--instructions-i-765-categories');
    if (tableCategories) {
      const categoryTable = tableCategories.dataset.category;
      const containersCategory = tableCategories.getElementsByClassName('category-container');
      let i;
      for (i = 0; i < containersCategory.length; i++) {
        if (containersCategory[i].dataset.category.toLowerCase() === categoryTable) {
          containersCategory[i].classList.remove('hide');
        }
      }
    }

    if (customForm === null && getCookie('customForm') === 'null') {
      window.location.href = '/404';
    } else {
      if (!isError) {
        if (typeof data.form_name !== 'undefined') {
          if (shortName.toUpperCase() !== data.form_name) {
            window.location.href = '/404';
          }
        }
      } else {
        window.location.href = '/404';
      }
    }
  }, [data.form_name, shortName, isError]);
  return (
    <Layout
      nav
      typeHeader={templateStyle.header.type}
      footer={templateStyle.footer.type}
      prefooter={templateStyle.prefooter.type}
      pageContext={props.pageContext}
      location={location}
      dataSite={dataSite}
    >
      <Section light name={`pdf`}>
        <Wrapper middle row>
          <Column colMd={`12`}>
            <Title tag={`h1`} content={name} fontStyle="centered" />
          </Column>
        </Wrapper>
        <Wrapper middle row>
          <Column colMd={`12`}>
            {sectionPdfNote.content.map((content, key) => {
              if (content.textBody) {
                return (
                  <div className={`m-blockquote m-blockquote--alert`} key={key}>
                    {content.textBody && <RichText content={content.textBody} />}
                  </div>
                );
              }
              return '';
            })}
          </Column>
          {navLateral && (
            <Column borderRight colMd={`12`} colLg={`3`}>
              <div data-js-sticky={`left`}>
                <NavLateral
                  activeURL={`/` + props.pageContext.slug}
                  content={navLateral[0].navMenu}
                />
                <>
                  {/*<Input value={`Download instructions`} placeholder={`Download instructions`} type={`button`} name={`download`} id={`downloadPDF`} onClick={() => window.print()} />*/}
                </>
              </div>
            </Column>
          )}
          <Column spaceLeft={!!navLateral} colMd={`12`} colLg={navLateral ? `9` : `12`}>
            {!isLoading ? (
              <div id={`instruction-${shortName}`} data-category={arrayCat}>
                {sectionInstructions.content.map((content, k) => {
                  if (content.textBody) {
                    return (
                      <div key={k}>
                        {sectionInstructions.title && <Title content={sectionInstructions.title} />}
                        {content.textBody && <RichText content={content.textBody} />}
                      </div>
                    );
                  }
                  if (content.stepsInstruction) {
                    return (
                      <CardGroup key={k}>
                        {content.stepsInstruction.map((step, z) => {
                          return (
                            <Card
                              col={12}
                              colLg={12}
                              key={z}
                              borderRadius
                              white
                              shadow
                              marg0={z === 0}
                            >
                              <div id={step.link} />
                              <Title size="small" content={step.title} />

                              {step.cardsInstruction.map((text, t) => {
                                if (!text.category) {
                                  return text.textdBody && <RichText key={t} content={text.textdBody} />;
                                } else {
                                  let arraySplit = text.category.category.split(';');
                                  if (
                                    (text.application && text.application === application) ||
                                    !text.application
                                  ) {
                                    return (
                                      <div key={t}>
                                        {arraySplit.map((item, key) => {
                                          if (arrayCat.includes(item)) {
                                            return (text.textdBody && <RichText
                                              key={key}
                                              categoryInstraction={item}
                                              content={text.textdBody}
                                            />
                                            );
                                          }
                                          return '';
                                        })}
                                      </div>
                                    );
                                  }
                                  return '';
                                }
                              })}
                            </Card>
                          );
                        })}
                      </CardGroup>
                    );
                  }
                  return '';
                })}
              </div>
            ) : (
              <p>{textLoading}</p>
            )}
          </Column>
        </Wrapper>
      </Section>
    </Layout>
  );
};

export default Instructions;
