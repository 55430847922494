import React from 'react';
import './select.scss';
import PropTypes from 'prop-types';

const Select = (props) => {
  return (
    <div className="m-input--group">
      <label className={`m-select--label`} htmlFor={props.name}>
        {props.label}
      </label>
      <select
        defaultValue={props.optionSelected}
        data-js-redirect={props.jsRedirect}
        className={props.className ? props.className + ` m-select` : `m-select`}
        name={props.name}
        id={props.id}
      >
        {props.items.map((option, key) => {
          return (
            <option key={key} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Select;
