import React from 'react';
import classNames from 'classnames';
import './navLateral.scss';
import { Link } from 'gatsby';
import Select from '../Form/Select/select';

export const NavLateral = (props) => {
  const classnames = classNames('m-nav-lateral', {
    'm-nav-lateral--align-right': props.alightRight,
    'm-nav-lateral--limit-width': props.limitwidth,
    //responsive select
    'm-nav-lateral--responsive-select': props.responsiveSelect,
  });

  const navMenu = props.content;
  let dataSelect = [];
  props.responsiveSelect &&
    navMenu.forEach((nav) => {
      dataSelect.push({
        value: nav.menuItemLink,
        label: nav.menuItem + (nav.menuLabel !== null ? ` ` + nav.menuLabel : ''),
      });
    });

  return (
    <div className={classnames}>
      <ul>
        {navMenu.map((nav, key) => {
          return (
            <li key={key}>
              <Link
                className={nav.menuItemLink === props.activeURL ? `active` : ``}
                to={`${nav.menuItemLink}`}
              >
                {nav.menuItem} <span className={`m-nav-lateral--label`}>{nav.menuLabel}</span>
              </Link>
            </li>
          );
        })}
      </ul>
      {props.responsiveSelect && (
        <Select
          jsRedirect={props.jsRedirect}
          className={`m-nav-lateral--responsive-selector`}
          name={`m-nav-lateral--responsive-selector`}
          items={dataSelect}
          optionSelected={props.activeURL}
        />
      )}
    </div>
  );
};
